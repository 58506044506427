import './scroll-pane.scss';

import React from 'react';
import classnames from 'classnames';

interface Props extends React.HTMLProps<HTMLDivElement> {
  verticalOnly?: boolean;
}

const ScrollPane = React.forwardRef<HTMLDivElement, Props>(({ children, className, verticalOnly, ...props }, ref) => {
  return (
    <div ref={ref} {...props} className={classnames('scroll-pane', className, { 'vertical-only': verticalOnly })}>
      {children}
    </div>
  );
});

export default ScrollPane;
