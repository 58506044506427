import { forwardRef, useImperativeHandle } from 'react';
import { Map as LeafletMap } from 'leaflet';
import { useMap } from 'react-leaflet';

const InternalMapRef = forwardRef<{map: LeafletMap}>((props, ref) => {
  const map = useMap();

  useImperativeHandle(ref, () => ({
    map,
  }));

  return null;
});

export default InternalMapRef;
