import thunk from 'redux-thunk';
import { History } from 'history';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import { reducers } from './rootReducer';
import pageChangeMiddleware from './middleware/pageChangeMiddleware';

export default function makeStore(history: History) {
  const windowIsDefined = typeof window === 'undefined' ? undefined : (window as any);
  let devTools = false;
  if (windowIsDefined && windowIsDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    devTools = true; // handles dev tools
  }

  const rootReducer = combineReducers({
    router: connectRouter(history),
    ...reducers,
  });

  return configureStore({
    reducer: rootReducer,
    middleware: [ routerMiddleware(history), thunk, pageChangeMiddleware ],
    devTools: devTools,
  });
}
