export const commonActions = {
  LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
  PATH_LOCATION_CHANGE: 'common/PathLocationChange',
  AREA_LOCATION_CHANGE: 'common/AreaLocationChange',
  DOWNLOAD_FILE: 'common/DownloadFile',
  LOGOUT_USER: 'common/logoutUser',
  UNAUTHORIZED: 'common/unauthorized',
};

export const unauthorized = () => {
  return {
    type: commonActions.UNAUTHORIZED,
  };
};
