import React, { ReactNode, useEffect, useState } from 'react';
import { LatLngLiteral, LeafletEvent } from 'leaflet';

import { getBoundingBoxFromMapBounds, MapBoundingBox } from 'core';
import { LeafletMap, Map, MapProps } from 'Components';

interface Props extends MapProps {
  enableLocationSearch?: boolean;
  enableGps?: boolean;
  onMapBoundingBoxLoaded?: (boundingBox: MapBoundingBox) => void;
  onMapBoundingBoxChange?: (boundingBox: MapBoundingBox) => void;
  dynamicView?: {
    center: LatLngLiteral;
    zoom: number;
  }
  children?: ReactNode;
}

const HousesMap = ({ children, events, enableLocationSearch = true, enableGps = true, dynamicView, ...props }: Props) => {
  const [ mapInstance, setMapInstance ] = useState<LeafletMap | undefined>();

  useEffect(() => {
    if(!mapInstance) {
      return;
    }

    if(props.onMapBoundingBoxLoaded) {
      props.onMapBoundingBoxLoaded(getBoundingBoxFromMapBounds(mapInstance.getBounds()));
    }
  }, [ mapInstance ]);

  useEffect(() => {
    if(!dynamicView) {
      return;
    }

    if(!mapInstance) {
      return;
    }

    mapInstance.setView(dynamicView.center, dynamicView.zoom);
  }, [ dynamicView?.center.lat, dynamicView?.center.lng, dynamicView?.zoom ]);

  function handleMapMoveEnd(event: LeafletEvent) {
    if(props.onMapBoundingBoxChange && mapInstance) {
      props.onMapBoundingBoxChange(getBoundingBoxFromMapBounds(mapInstance.getBounds()));
    }

    if(events?.moveend) {
      events.moveend(event);
    }
  }

  return (
    <Map
      {...props}
      ref={(ref) => {
        setMapInstance(ref?.map);
      }}
      events={{
        ...events,
        moveend: handleMapMoveEnd,
        click: console.log,
      }}>
      {children}
    </Map>
  );
};

export default HousesMap;
