import React from 'react';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import { MarkerProps } from 'react-leaflet/types/Marker';

import { getHouseDifferenceColor } from 'core';
import { House } from 'store/houses/models';
import { HouseMarkerPopup } from 'Components';

interface Props extends MarkerProps {
  house: House;
}

const HouseMarker = ({ house, ...props }: Props) => {
  const icon = L.divIcon({
    className: 'my-custom-pin',
    iconAnchor: [ 15, 24 ],
    // labelAnchor: [ -6, 0 ],
    popupAnchor: [ 0, -24 ],
    html: `<span class="artifact-marker" style="background-color: ${getHouseDifferenceColor(house)}" />`,
  });

  return (
    <Marker icon={icon} {...props}>
      {house && <HouseMarkerPopup house={house} />}
    </Marker>
  );
};

export default HouseMarker;
