import { Dispatch, ThunkAction } from '@reduxjs/toolkit';
import { callApi } from 'core';
import { housesSlice, HousesState } from './slice';
import { RootState } from '../rootReducer';
import { House, SearchHousesRequest } from './models';
import appConfig from '../../appConfig';
import housesActions from './actions';

const housesLoadingHandler = (dispatch: Dispatch<any>, loadingStateSetter: (loadingState: HousesState, isLoading: boolean) => void) => (isLoading: boolean) => {
  dispatch(housesSlice.actions.setLoading({
    isLoading,
    handle: loadingStateSetter,
  }));
};

export const searchHouses = (request: SearchHousesRequest): ThunkAction<any, RootState, any, any> => (dispatch) => {
  return callApi({
    loading: housesLoadingHandler(dispatch, (state, isLoading) => state.loading.isSearchingHouses = isLoading),
    getRequest: (superagent) => superagent.post(`${appConfig.baseUrl}/api/houses/search`).send(request),
    onSuccess: (data: House[]) => {
      dispatch(housesActions.setSearchedHouses(data));
    },
  });
};
