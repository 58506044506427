import React from 'react';
import classnames from 'classnames';

import Icon, { IconExtendableProps } from './Icon';

interface Props extends IconExtendableProps {
  isSelected?: boolean;
  isIndeterminate?: boolean;
  isUnavailable?: boolean;
  isCalculatedSelected?: boolean;
}

const CheckboxIcon = ({ isIndeterminate, isSelected, isCalculatedSelected, isUnavailable, className, ...rest }: Props) => {
  return (
    <Icon
      {...rest}
      className={classnames({
        'mdi-checkbox-blank-outline': !isUnavailable && !isSelected && !isIndeterminate && !isCalculatedSelected,
        'mdi-checkbox-intermediate': !isSelected && isIndeterminate,
        'mdi-checkbox-blank-off-outline': isUnavailable,
        'mdi-checkbox-marked': isCalculatedSelected,
        'mdi-checkbox-marked-outline': isSelected,
      })}
    />
  );
};

export default React.memo(CheckboxIcon);
