import { RefObject, useEffect } from 'react';

const useOutsideClickListener = (dropdownRef: RefObject<HTMLElement>, handler: (event: MouseEvent) => void, deps: ReadonlyArray<any>) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        handler(event);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ dropdownRef, ...deps ]);
};

export default useOutsideClickListener;
