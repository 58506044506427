import { housesSlice } from './slice';
import * as housesApiActions from './apiActions';

const housesActions = {
  ...housesSlice.actions,
  async: {
    ...housesApiActions,
  },
};

export default housesActions;
