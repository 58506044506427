import { Point } from '../models';

export interface SearchHousesRequest {
  bounds?:Point[];
  since?:string;
}

export interface House {
  id:number;
  url:string;
  address?:string;
  city?:string;
  state?:string;
  zip?:string;
  hoaPerMonth?:string;
  countyName:string;
  beds?:number;
  baths?:number;
  propertyType:string;
  status:string;
  pricePerSqFt?:number;
  latitude:number;
  longitude:number;
  streetViewUrl:string;
  schoolDistrictName:string;
  numStories?:number;
  yearBuilt?:number;
  yearRenovated?:number;
  sqFtFinished?:number;
  totalSqFt?:number;
  lotSqFt?:number;
  taxableLandValue?:number;
  taxableImprovementValue?:number;
  taxRollYear?:number;
  mostRecentTaxesDue?:number;
  listDate?:string;
  listPrice?:number;
  mostRecentPriceChangeDate?:string;
  numPriceChanges:number;
  soldDate?:string;
  soldPrice?:number;
  priceChangesJson?:string;
  masterBedroomDimensions?:string;
  masterBedroomLevel?:string;
  numFullBaths?:number;
  numHalfBaths?:number;
  interiorFeatures?:string;
  basementFeatures?:string;
  appliances?:string;
  numGarageSpaces?:number;
  garageFeatures?:string;
  waterFacilities?:string;
  heatingCoolingFacilities?:string;
  heatFuel?:string;
}

export enum HouseColumn {
  Url = 'url',
  Address = 'address',
  City = 'city',
  State = 'state',
  Zip = 'zip',
  HoaPerMonth = 'hoaPerMonth',
  CountyName = 'countyName',
  Beds = 'beds',
  Baths = 'baths',
  PropertyType = 'propertyType',
  Status = 'status',
  PricePerSqFt = 'pricePerSqFt',
  Latitude = 'latitude',
  Longitude = 'longitude',
  StreetViewUrl = 'streetViewUrl',
  SchoolDistrictName = 'schoolDistrictName',
  NumStories = 'numStories',
  YearBuilt = 'yearBuilt',
  YearRenovated = 'yearRenovated',
  SqFtFinished = 'sqFtFinished',
  TotalSqFt = 'totalSqFt',
  LotSqFt = 'lotSqFt',
  TaxableLandValue = 'taxableLandValue',
  TaxableImprovementValue = 'taxableImprovementValue',
  TaxRollYear = 'taxRollYear',
  MostRecentTaxesDue = 'mostRecentTaxesDue',
  ListDate = 'listDate',
  ListPrice = 'listPrice',
  MostRecentPriceChangeDate = 'mostRecentPriceChangeDate',
  NumPriceChanges = 'numPriceChanges',
  SoldDate = 'soldDate',
  SoldPrice = 'soldPrice',
  PriceChangesJson = 'priceChangesJson',
  MasterBedroomDimensions = 'masterBedroomDimensions',
  MasterBedroomLevel = 'masterBedroomLevel',
  NumFullBaths = 'numFullBaths',
  NumHalfBaths = 'numHalfBaths',
  InteriorFeatures = 'interiorFeatures',
  BasementFeatures = 'basementFeatures',
  Appliances = 'appliances',
  NumGarageSpaces = 'numGarageSpaces',
  GarageFeatures = 'garageFeatures',
  WaterFacilities = 'waterFacilities',
  HeatingCoolingFacilities = 'heatingCoolingFacilities',
  HeatFuel = 'heatFuel',
  Difference = 'difference',
}
