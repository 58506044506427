import _ from 'lodash';
import { facets } from '../../core';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';

export const selectFilteredHouses = createSelector([
  (state: RootState) => state.houses.ui.houses,
  (state: RootState) => state.houses.ui.facetValues,
], (houses, facetValues) => {
  return _.filter(houses, (house) => {
    return _.every(facets, (facet) => {
      const [ min, max ] = facetValues[facet.id];
      const value = facet.getValue(house);
      return _.isNil(value) || (value >= min && value <= max);
    });
  });
});
