import superagent, { Response as SuperAgentResponse } from 'superagent';
import statusCodes from 'http-status-codes';
import { toast } from 'react-toastify';
import { ApiCallParameters } from './models';
import { Http403ForbiddenStatusDetail } from '../../enums';
import Swal from 'sweetalert2';

const handleErrorFeedback = async (response: SuperAgentResponse) => {
  console.error({ response });
  const message: string = response?.body?.error?.message;

  if (response.status === statusCodes.NOT_FOUND) {
    toast.error('Not Found');
  } else if (response.status === 400) {
    if (message) {
      toast.warn(message);
    }
  } else if (response.status === 403) {
    const statusDetail = response?.body?.statusDetail;
    if(statusDetail === Http403ForbiddenStatusDetail.TermsOfServiceNotAgreed) {
      const { isDismissed } = await Swal.fire({
        icon: 'success',
        title: 'Terms of Service',
        text: 'Before utilizing this site you must agree to the terms of service. If you do not accept the terms, you cannot continue to the application.',
        confirmButtonText: 'I Agree',
        showCancelButton: true,
        cancelButtonText: 'Log Out',
      });

      if(isDismissed) {
        window.location.href = '/Account/Logout';
        return;
      }

      await callApi({
        getRequest: (superagent) => superagent.put('/api/users/current/tos/accept'),
      });

      window.location.reload(true);
    }
  } else {
    toast.error('An unknown error has occurred. Please refresh the page or try the action again.');
  }
};

export const callApi = async <TData extends any>(
  apiCallParameters: ApiCallParameters<TData>
): Promise<TData> => {
  const {
    getRequest,
    onSuccess,
    onSuccessToast,
    onFailure,
    onComplete,
    loading,
    useDefaultErrorHandlingFeedback = true,
  } = apiCallParameters;

  if (loading) {
    loading(true);
  }

  try {
    const response = await getRequest(superagent)
      .set('X-Requested-With', 'XMLHttpRequest');

    const { body } = response;

    if (onSuccess) {
      onSuccess(body, response);
    }

    if (onSuccessToast) {
      onSuccessToast(toast, body);
    }

    if (onComplete) {
      onComplete(response);
    }

    return body;
  } catch (error) {
    const { response } = error;
    if (useDefaultErrorHandlingFeedback) {
      await handleErrorFeedback(response);
    }

    if (onFailure) {
      onFailure(response);
    }

    if (onComplete) {
      onComplete(response);
    }

    throw error;
  } finally {
    if (loading) {
      loading(false);
    }
  }
};
