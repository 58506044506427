import { LeafletEventHandlerFnMap } from 'leaflet';
import { useMapEvents } from 'react-leaflet';

interface Props {
  events?: LeafletEventHandlerFnMap
}

const InternalMapEvents = ({ events }: Props) => {
  useMapEvents(events || {});

  return null;
};

export default InternalMapEvents;
