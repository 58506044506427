export interface SiteConfig {
  baseUrl: string;
}

const appConfig: SiteConfig = {
  // baseUrl: 'https://localhost:44357',
  baseUrl: 'https://scottrupprecht.azurewebsites.net',
};

export default appConfig;
