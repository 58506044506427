import React from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { FlexRow, HouseFacets, Panel, VerticalOnlyScrollPane } from 'Components';
import { useStateSelector } from 'hooks';
import { housesActions } from 'store/houses';

import Routes from './Routes';
import Navbar from './Navbar/Navbar';
import { Button } from 'reactstrap';

const App = () => {
  const dispatch = useDispatch();
  const showFilterPanel = useStateSelector(state => state.houses.ui.showFilterPanel);
  return (
    <>
      <Helmet titleTemplate="%s | SR Homes" defaultTitle="SR Homes" />

      <div id="wrapper">
        <div className="content-page">
          <div className="content">
            <Navbar />
            <main>
              <Routes />
            </main>
          </div>
        </div>
      </div>

      <div className={showFilterPanel ? '' : 'd-none'}>
        <div className="filters-overlay" onClick={() => dispatch(housesActions.toggleFilterPanel())} />
        <div className="filters-sidebar">
          <div className="border-bottom p-1">
            <FlexRow justifyBetween>
              <div />
              <FlexRow alignCenter>
                <Button size="lg" color="link" onClick={() => dispatch(housesActions.toggleFilterPanel())}>
                  Close <i className="mdi mdi-close" />
                </Button>
              </FlexRow>
            </FlexRow>
          </div>
          <Panel>
            <VerticalOnlyScrollPane className="p-3">
              <HouseFacets />
            </VerticalOnlyScrollPane>
          </Panel>
        </div>
      </div>

      <ToastContainer
        hideProgressBar
        position="bottom-center"
      />
    </>
  );
};

export default App;
