export enum SortDirection {
  Ascending = 1,
  Descending = 2
}

export enum ArtifactType {
  Story = 1,
  Image = 2,
}

export enum ArtifactStatus {
  Submitted = 1,
  Rejected = 2,
  Approved = 3,
}

export enum AssetType {
  OrganizationLogo = 1,
  OrganizationAvatar = 2,
  Artifact = 3,
}

export enum Http403ForbiddenStatusDetail {
  TermsOfServiceNotAgreed = 1
}

export enum IsApprovedArtifactFilter {
  All = 1,
  IsApproved = 2,
  IsNotApproved = 3
}
