import _ from 'lodash';

// Finds the first segment of text after the first / of a string. /segmentA/segmentB => selects segmentA
const areaRegex = /^\/([^/]+)/;

export const getAreaFromPathname = (pathname?: string): string | undefined => {
  if (!pathname) {
    return undefined;
  }

  const result = areaRegex.exec(pathname);

  return _.has(result, 1) ? result![1] : undefined;
};
