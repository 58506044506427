import React from 'react';
import Flex, { FlexExtendableProps } from './Flex';

interface Props extends FlexExtendableProps {
  children: any;
}

const FlexColumn = ({ direction = 'col', children, ...rest }: Props) => {
  return (
    <Flex
      {...rest}
      direction={direction}
    >
      {children}
    </Flex>
  );
};

export default FlexColumn;
