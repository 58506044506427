import React from 'react';
import { Button, Container, Nav } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { housesActions } from 'store/houses';
import { FlexRow } from 'Components';

const Navbar = () => {
  const dispatch = useDispatch();
  return (
    <Nav className="navbar-custom">
      <Container className="d-flex flex-row align-items-center justify-content-between pl-2" fluid>
        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <FlexRow alignCenter className="text-white" childSpacingX={1}>
              <i className="mdi mdi-48px mdi-home-account" />
              <span>SR Homes</span>
            </FlexRow>
          </li>
        </ul>
        <div>
          <Button color="light" onClick={() => dispatch(housesActions.toggleFilterPanel())}>
            <i className="mdi mdi-filter" /> Show Filters
          </Button>
        </div>
      </Container>
    </Nav>
  );
};

export default Navbar;
