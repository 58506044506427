import { commonActions } from '../common/actions';

export interface SiteState {
  currentPathname?: string;
  currentLocation?: string;
  lastPathname?: string;
  lastLocation?: string;
}

const initState: SiteState = {
  lastLocation: '',
  lastPathname: '',
};

export default (state = initState, action: any): SiteState => {
  switch (action.type) {
  case commonActions.LOCATION_CHANGE: {
    const { location } = action.payload;

    return {
      ...state,
      lastLocation: state.currentLocation,
      lastPathname: state.currentPathname,
      currentPathname: location.pathname,
      currentLocation: location.pathname + location.search + location.hash,
    };
  }
  default: {
    return state;
  }
  }
};
