import { House, HouseColumn } from '../store/houses/models';
import React, { ReactNode } from 'react';
import { getHouseDifferenceColor, getHouseListSalePriceDifference } from './house';
import _ from 'lodash';
import { FlexRow } from '../Components';
import numeral from 'numeral';

export interface AvailableColumn {
  label: string;
  key: string;
  value: HouseColumn;
  renderer?: (value: any, house: House) => ReactNode;
}

const currencyRenderer = (value?: number) => numeral(value).format('$0,0.00');

export const availableColumns: AvailableColumn[] = [
  {
    label: 'RedFin Url',
    key: 'url',
    value: HouseColumn.Url,
    renderer: (value) => (
      <a rel="noreferrer noopener" href={value} target="_blank">View Listing</a>
    ),
  },
  { label: 'Address', value: HouseColumn.Address, key: 'address' },
  { label: 'City', value: HouseColumn.City, key: 'city' },
  { label: 'State', value: HouseColumn.State, key: 'state' },
  { label: 'Zip', value: HouseColumn.Zip, key: 'zip' },
  { label: 'County', value: HouseColumn.CountyName, key: 'countyName' },
  { label: 'Hoa/Month', value: HouseColumn.HoaPerMonth, key: 'hoaPerMonth' },
  { label: 'Beds', value: HouseColumn.Beds, key: 'beds' },
  { label: 'Baths', value: HouseColumn.Baths, key: 'baths' },
  { label: 'Property Type', value: HouseColumn.PropertyType, key: 'propertyType' },
  { label: 'Status', value: HouseColumn.Status, key: 'status' },
  { label: 'Price Per Sq Ft', value: HouseColumn.PricePerSqFt, key: 'pricePerSqFt' },
  { label: 'Latitude', value: HouseColumn.Latitude, key: 'latitude' },
  { label: 'Longitude', value: HouseColumn.Longitude, key: 'longitude' },
  {
    label: 'Google Street View',
    value: HouseColumn.StreetViewUrl,
    key: 'streetViewUrl',
    renderer: (value) => (
      <a rel="noreferrer noopener" href={value} target="_blank">View Street View</a>
    ),
  },
  { label: 'School District', value: HouseColumn.SchoolDistrictName, key: 'schoolDistrictName' },
  { label: '# Stories', value: HouseColumn.NumStories, key: 'numStories' },
  { label: 'Year Built', value: HouseColumn.YearBuilt, key: 'yearBuilt' },
  { label: 'Year Renovated', value: HouseColumn.YearRenovated, key: 'yearRenovated' },
  { label: 'Square Feet Finished', value: HouseColumn.SqFtFinished, key: 'sqFtFinished' },
  { label: 'Total Square Ft', value: HouseColumn.TotalSqFt, key: 'totalSqFt' },
  { label: 'Lot Square Ft', value: HouseColumn.LotSqFt, key: 'lotSqFt' },
  { label: 'Taxable Land Value', value: HouseColumn.TaxableLandValue, renderer: currencyRenderer, key: 'taxableLandValue' },
  { label: 'Taxable Improvement Value', value: HouseColumn.TaxableImprovementValue, renderer: currencyRenderer, key: 'taxableImprovementValue' },
  { label: 'Tax Roll Year', value: HouseColumn.TaxRollYear, key: 'taxRollYear' },
  { label: 'Most Recent Taxes Due', value: HouseColumn.MostRecentTaxesDue, renderer: currencyRenderer, key: 'mostRecentTaxesDue' },
  { label: 'List Date', value: HouseColumn.ListDate, key: 'listDate' },
  { label: 'List Price', value: HouseColumn.ListPrice, renderer: currencyRenderer, key: 'listPrice' },
  { label: 'Most Recent Price Change Date', value: HouseColumn.MostRecentPriceChangeDate, key: 'mostRecentPriceChangeDate' },
  { label: 'Num Price Changes', value: HouseColumn.NumPriceChanges, key: 'numPriceChanges' },
  { label: 'Sold Date', value: HouseColumn.SoldDate, key: 'soldDate' },
  { label: 'Sold Price', value: HouseColumn.SoldPrice, renderer: currencyRenderer, key: 'soldPrice' },
  {
    label: 'Difference', value: HouseColumn.Difference, key: 'difference', renderer: (value, house) => {
      const difference = getHouseListSalePriceDifference(house);

      if(_.isNil(difference)) {
        return null;
      }

      return (
        <FlexRow alignCenter childSpacingX={1}>
          <div style={{ width: 20, height: 20, backgroundColor: getHouseDifferenceColor(house) }}/>
          <span>{!_.isNil(difference) ? numeral(difference).format('$0,0.00') : undefined}</span>
        </FlexRow>
      );
    },
  },
  { label: 'PriceChangesJson', value: HouseColumn.PriceChangesJson, key: 'priceChangesJson' },
  { label: 'Master Bedroom Dimensions', value: HouseColumn.MasterBedroomDimensions, key: 'masterBedroomDimensions' },
  { label: 'Master Bedroom Level', value: HouseColumn.MasterBedroomLevel, key: 'masterBedroomLevel' },
  { label: '# Full Baths', value: HouseColumn.NumFullBaths, key: 'numFullBaths' },
  { label: '# Half Baths', value: HouseColumn.NumHalfBaths, key: 'numHalfBaths' },
  { label: 'Interior Features', value: HouseColumn.InteriorFeatures, key: 'interiorFeatures' },
  { label: 'Basement Features', value: HouseColumn.BasementFeatures, key: 'basementFeatures' },
  { label: 'Appliances', value: HouseColumn.Appliances, key: 'appliances' },
  { label: '# Garage Spaces', value: HouseColumn.NumGarageSpaces, key: 'numGarageSpaces' },
  { label: 'Garage Features', value: HouseColumn.GarageFeatures, key: 'garageFeatures' },
  { label: 'Water', value: HouseColumn.WaterFacilities, key: 'waterFacilities' },
  { label: 'Heating/Cooling', value: HouseColumn.HeatingCoolingFacilities, key: 'heatingCoolingFacilities' },
  { label: 'Heat Fuel', value: HouseColumn.HeatFuel, key: 'heatFuel' },
];
