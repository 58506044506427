import { House } from 'store/houses/models';
import { getHouseListSalePriceDifference } from './house';
import { ReactNode } from 'react';
import numeral from 'numeral';
import { parseISO, differenceInDays } from 'date-fns';
import _ from 'lodash';

export enum Facet {
  Difference = 1,
  DaysSinceSoldDate = 2,
  DaysSinceListDate = 3,
  SoldPrice = 4,
}

export interface FacetDefinition {
  id: Facet;
  label: string;
  getValue: (houses: House) => number | undefined;
  defaultMinMax: [number, number],
  doNotAdjustMinMax?: boolean;
  rangeSlider: {
    step: number;
    formatValues: (value: number) => ReactNode;
  }
}

export const facets: FacetDefinition[] = [
  {
    id: Facet.SoldPrice,
    label: 'Sold Price',
    defaultMinMax: [ 250000, 375000 ],
    doNotAdjustMinMax: true,
    getValue: (house) => house.soldPrice,
    rangeSlider: {
      step: 1000,
      formatValues: (value) => numeral(value).format('$0,0.00'),
    },
  },
  {
    id: Facet.DaysSinceSoldDate,
    label: 'Sold Date',
    doNotAdjustMinMax: true,
    defaultMinMax: [ -60, 0 ],
    getValue: (house) => {
      const { soldDate } = house;

      if(!soldDate) {
        return undefined;
      }

      const parsed = parseISO(soldDate!);

      return differenceInDays(parsed, new Date());
    },
    rangeSlider: {
      step: 1,
      formatValues: (value) => {
        return `${value} Days Ago`;
      },
    },
  },
  {
    id: Facet.Difference,
    label: 'Difference Range',
    defaultMinMax: [ -10000, 50000 ],
    doNotAdjustMinMax: true,
    getValue: (house) => getHouseListSalePriceDifference(house),
    rangeSlider: {
      step: 1000,
      formatValues: (value) => {
        const absValue = Math.abs(value);
        const formattedAbsValue = numeral(absValue).format('$0,0.00');

        if(value < 0) {
          return `${formattedAbsValue} under`;
        }

        if(value > 0) {
          return `${formattedAbsValue} over`;
        }

        return 'At List';
      },
    },
  },
  {
    id: Facet.DaysSinceListDate,
    label: 'List Date',
    defaultMinMax: [ -30, 0 ],
    getValue: (house) => {
      const { listDate } = house;

      if(!listDate) {
        return undefined;
      }

      const parsed = parseISO(listDate!);

      return differenceInDays(parsed, new Date());
    },
    rangeSlider: {
      step: 1,
      formatValues: (value) => {
        return `${value} Days Ago`;
      },
    },
  },
];


// @ts-ignore
export const defaultMinMaxes: Record<Facet, [number, number]> = {};

_.each(facets, (facet) => {
  defaultMinMaxes[facet.id] = facet.defaultMinMax;
});
