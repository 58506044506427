import React, { ReactNode, useEffect } from 'react';
import { getTrackBackground, Range } from 'react-range';

interface Props {
  values: [number, number];
  step: number;
  min: number;
  max: number;
  formatValues: (value: number) => ReactNode;
  onRangeChange?: (range: [number, number]) => void;
}

const RangeSlider = ({ min, max, step, values: controlledValues, ...props }: Props) => {
  const [ values, setValues ] = React.useState<[number, number]>(controlledValues);

  useEffect(() => {
    setValues(controlledValues);
  }, [ controlledValues ]);

  return (
    <Range
      values={values}
      step={step}
      min={min}
      max={max}
      draggableTrack
      onChange={([ min, max ]) => setValues([ min, max ])}
      onFinalChange={([ min, max ]) => props.onRangeChange?.([ min, max ])}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            top: 50,
            height: '36px',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: '5px',
              width: '100%',
              borderRadius: '4px',
              background: getTrackBackground({
                values,
                colors: [ '#ccc', '#548BF4', '#ccc' ],
                min: min,
                max: max,
                rtl: false,
              }),
              alignSelf: 'center',
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ index, props: thumbProps, isDragged }) => (
        <div
          {...thumbProps}
          style={{
            ...thumbProps.style,
            height: '40px',
            width: '42px',
            borderRadius: '4px',
            backgroundColor: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 2px 6px #AAA',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '-56px',
              color: '#fff',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
              fontSize: '14px',
              fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
              padding: '4px',
              borderRadius: '4px',
              backgroundColor: '#548BF4',
              width: 'auto',
            }}
          >
            {props.formatValues(values[index])}
          </div>
          <div
            style={{
              height: '16px',
              width: '5px',
              backgroundColor: isDragged ? '#548BF4' : '#CCC',
            }}
          />
        </div>
      )}
    />
  );
};

export default RangeSlider;
