import React, { ReactNode, useRef } from 'react';
import _ from 'lodash';
import { ReactSortable } from 'react-sortablejs';

interface Props<T> {
  items: T[];
  idAccessor: (item: T) => any;
  onOrderChange: (items: T[]) => void;
  children?: ReactNode;
}

const Sortable = <T extends object>({ items, children, ...props }: Props<T>) => {
  const localItems = useRef<T[]>(items);

  function getSortableItem(item: T) {
    return {
      ...item,
      id: props.idAccessor(item),
    };
  }

  function handleSetList(items: T[]) {
    localItems.current = items;
  }

  function handleOnEnd() {
    const listStructureHasChanged = localItems.current.length !== items.length;
    const listHasChanges = !listStructureHasChanged && _.some(items, (item, i) => props.idAccessor(item) !== props.idAccessor(localItems.current[i]));

    if (listHasChanges) {
      props.onOrderChange(localItems.current);
    }
  }

  return (
    <ReactSortable list={items.map(getSortableItem)} setList={handleSetList} onEnd={handleOnEnd}>
      {children}
    </ReactSortable>
  );
};

export default Sortable;
