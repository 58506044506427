import _ from 'lodash';

export const getNumberHash = <T>(entities: T[], idSelector: (item: T) => number): {[id: number]: T} => {
  const hash: { [id: number]: T } = {};

  _.each(entities, (entity) => {
    hash[idSelector(entity)] = entity;
  });

  return hash;
};

export const getNumberMappedEntities = <T>(entities: { [id: number]: T }, ids: number[]): T[] => {
  return _.map(ids, i => entities[i]);
};

export const getStringMappedEntities = <T>(entities: { [id: string]: T }, ids: string[]): T[] => {
  return _.map(ids, i => entities[i]);
};
