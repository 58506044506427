import React from 'react';
import ScrollPane from './ScrollPane';

interface Props extends React.HTMLProps<HTMLDivElement> {
  ref: React.Ref<HTMLDivElement>;
}

const VerticalOnlyScrollPane = React.forwardRef<HTMLDivElement, Props>(({ children, ...props }, ref) => {
  return (
    <ScrollPane ref={ref} {...props} verticalOnly>
      {children}
    </ScrollPane>
  );
});

export default VerticalOnlyScrollPane;
