import './base-table.scss';
import DataGrid, { DataGridProps } from 'react-data-grid';
import React from 'react';
import classnames from 'classnames';

export interface BaseTableProps<TRow, TSummaryRow = unknown> extends DataGridProps<TRow, TSummaryRow> {
  rowKeyGetter: (data: TRow) => React.Key;
  hideCellBorder?: boolean;
  fillHeight?: boolean;
  onRowChange?: (row: TRow) => void;
}

const BaseTable = <TRow extends any, TSummaryRow extends any = unknown>({
  className,
  fillHeight,
  hideCellBorder = true,
  ...props
}: BaseTableProps<TRow, TSummaryRow>) => {
  return (
    <DataGrid
      {...props}
      className={classnames('base-table', className, {
        'fill-height': fillHeight,
        'hide-cell-border': hideCellBorder,
      })}
    />
  );
};

export default BaseTable;
