import React from 'react';
import _ from 'lodash';
import { FormGroup, Label } from 'reactstrap';

import { useHouseFacets } from 'hooks';
import { RangeSlider } from 'Components';

const HouseFacets = () => {
  const { facets, facetMinMaxes, facetValues, setFacetValue } = useHouseFacets();

  return (
    <>
      {
        _.map(facets, (facet, i) => {
          return (
            <FormGroup key={facet.id}>
              <Label>{facet.label}</Label>

              <div className="px-4 pt-5 pb-2">
                <RangeSlider
                  values={facetValues[facet.id]}
                  formatValues={facet.rangeSlider.formatValues}
                  step={facet.rangeSlider.step}
                  onRangeChange={(values) => setFacetValue(facet.id, values)}
                  min={facetMinMaxes[facet.id][0]}
                  max={facetMinMaxes[facet.id][1]}
                />
              </div>

              {facets.length -1 !== i && (
                <hr />
              )}
            </FormGroup>
          );
        })
      }
    </>
  );
};

export default HouseFacets;
