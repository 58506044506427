import { combineReducers } from '@reduxjs/toolkit';
import { housesReducer } from './houses';
import { pathReducer } from './path';
// --PlopReducerImport--

export const reducers = {
  houses: housesReducer,
  path: pathReducer,
// --PlopReducerExport--
};

const rootReducer = combineReducers(reducers);
export type RootState = ReturnType<typeof rootReducer>;
