import { CSSProperties } from 'react';
import _ from 'lodash';

export const mergeCssProperties = (...args: (CSSProperties | undefined)[]) => {
  if (_.isEmpty(args)) {
    return undefined;
  }

  const [ first, ...rest ] = args;

  return _.reduce(rest, (merged, style) => {
    if (!style) {
      return merged;
    }

    return { ...merged, ...style };
  }, first);
};
