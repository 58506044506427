import React, { useState } from 'react';
import _ from 'lodash';

import { AvailableColumn, MapBoundingBox } from 'core';
import { House } from 'store/houses/models';
import { HouseMarker, HousesMap, MapFlexContainer, Panel } from 'Components';
import { Button } from 'reactstrap';
import HouseTable from './HouseTable';

interface Props {
  filteredHouses: House[];
  shownColumns: AvailableColumn[];
  handleBoundingBoxChange: (boundingBox: MapBoundingBox) => void;
}

const MobileHousesPanel = ({ filteredHouses, shownColumns, ...props }: Props) => {
  const [ isShowingTable, setIsShowingTable ] = useState(false);
  return (
    <Panel className="position-relative">
      <MapFlexContainer>
        <HousesMap
          onMapBoundingBoxLoaded={props.handleBoundingBoxChange}
          onMapBoundingBoxChange={props.handleBoundingBoxChange}
          zoom={12}
          center={{ lat: 43.026486454006594, lng: -87.9524535099127 }}>
          {/*<MarkerClusterGroup spiderfyOnMaxZoom={false} maxClusterRadius={40}>*/}
          {
            _.map(_.take(filteredHouses, 800), (house) => {
              return (
                <HouseMarker
                  key={house.id}
                  house={house}
                  position={[ house.latitude, house.longitude ]}
                />
              );
            })
          }
          {/*</MarkerClusterGroup>*/}
        </HousesMap>
      </MapFlexContainer>

      {isShowingTable && (
        <Panel className="mobile-table-container">
          <Panel className="pb-5">
            <HouseTable columns={shownColumns} houses={filteredHouses} />
          </Panel>
        </Panel>
      )}

      <div className="mobile-toggle-overlay">
        <Button color="light" onClick={() => setIsShowingTable(!isShowingTable)}>
          {isShowingTable ? 'Show Map' : 'Show Results'}
        </Button>
      </div>
    </Panel>
  );
};

export default MobileHousesPanel;
