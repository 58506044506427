import React from 'react';
import _ from 'lodash';

import { AvailableColumn } from 'core';
import { House } from 'store/houses/models';
import { BaseTable } from 'Components';
import { Column } from 'react-data-grid';

interface Props {
  columns: AvailableColumn[];
  houses: House[];
}

const HouseTable = ({ houses, columns: availableColumns }: Props) => {
  const tableColumns = availableColumns.map((column): Column<House> => ({
    key: column.key,
    resizable: true,
    name: column.label,
    formatter: !column.renderer ? undefined : ({ row }) => {
      return (
        <>
          {column.renderer!(_.get(row, column.key), row)}
        </>
      );
    },
  }));

  return (
    <BaseTable
      fillHeight
      rowKeyGetter={h => h.id.toString()}
      columns={tableColumns}
      rows={houses}
    />
  );
};

export default HouseTable;
