import './map-flex-container.scss';

import React, { ReactNode } from 'react';
import classnames from 'classnames';

interface Props extends React.HTMLProps<HTMLDivElement> {
  children: ReactNode;
}

const MapFlexContainer = ({ children, className, ...props }: Props) => {
  return (
    <div {...props} className={classnames('map-container', className)}>
      {children}
    </div>
  );
};

export default MapFlexContainer;
