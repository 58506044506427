import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import HousesPage from '../Pages/Houses/HousesPage';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={HousesPage} />
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
