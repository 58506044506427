import React from 'react';
import FlexColumn from '../Flex/FlexColumn';
import { FlexExtendableProps } from '../Flex/Flex';

interface Props extends FlexExtendableProps {}

const Panel = ({ children, overflowHidden = true, ...props }: Props) => {
  return (
    <FlexColumn {...props} fill overflowHidden>
      {children}
    </FlexColumn>
  );
};

export default Panel;
