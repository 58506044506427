import React from 'react';
import classnames from 'classnames';

import Div, { DivExtendableProps } from '../Div/Div';

export interface FlexExtendableProps extends DivExtendableProps {
  direction?: 'col' | 'row';
  overflowHidden?: boolean;
  center?: boolean;
  justifyCenter?: boolean;
  justifyBetween?: boolean;
  justifyStart?: boolean;
  justifyEnd?: boolean;
  alignCenter?: boolean;
}

interface Props extends FlexExtendableProps {
  children: any;
}

const Flex = ({ direction = 'col', overflowHidden, className, center, alignCenter, justifyCenter, justifyBetween, justifyStart, justifyEnd, children, ...rest }: Props) => {
  return (
    <Div
      {...rest}
      className={
        classnames('d-flex', className, {
          'flex-column': direction === 'col',
          'flex-row': direction === 'row',
          'justify-content-center': center || justifyCenter,
          'justify-content-start': justifyStart,
          'justify-content-end': justifyEnd,
          'justify-content-between': justifyBetween,
          'align-items-center': center || alignCenter,
          'overflow-hidden': overflowHidden,
        })
      }
    >
      {children}
    </Div>
  );
};

export default Flex;
