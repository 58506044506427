import { useEffect, useRef } from 'react';
import _ from 'lodash';

import { Facet, facets } from 'core';
import { useStateSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { housesActions } from '../store/houses';

const useHouseFacets = () => {
  const dispatch = useDispatch();
  const houses = useStateSelector((state) => state.houses.ui.houses);
  const minMaxes = useStateSelector((state) => state.houses.ui.minMaxes);
  const facetValues = useStateSelector((state) => state.houses.ui.facetValues);
  const minMaxInitializedRef = useRef(false);

  function setFacetValues(values: Record<Facet, [number, number]>) {
    dispatch(housesActions.setFacetValues(values));
  }

  function setMinMaxes(values: Record<Facet, [number, number]>) {
    dispatch(housesActions.setMinMaxes(values));
  }

  useEffect(() => {
    const updatedMinMaxes = { ...minMaxes };
    _.each(facets, (facet) => {
      const existingFacetMinMax = minMaxes[facet.id];
      const values = _.filter(_.map(houses, (house) => facet.getValue(house)), value => !_.isNil(value)).map(v => v!);
      const min = _.min(values);
      const max = _.max(values);

      if(_.isNil(min) || _.isNil(max)) {
        return;
      }

      const [ existingMin, existingMax ] = existingFacetMinMax;

      updatedMinMaxes[facet.id] = [
        min < existingMin ? min : existingMin,
        max > existingMax ? max : existingMax,
      ];
    });

    setMinMaxes(updatedMinMaxes);

    if(houses.length && !minMaxInitializedRef.current) {
      const applicableMinMaxesToApply: Partial<typeof updatedMinMaxes> = {};

      _.each(facets, (facet) => {
        if(facet.doNotAdjustMinMax) {
          return;
        }

        applicableMinMaxesToApply[facet.id] = updatedMinMaxes[facet.id];
      });

      setFacetValues({
        ...facetValues,
        ...applicableMinMaxesToApply,
      });
      minMaxInitializedRef.current = true;
    }
  }, [ houses ]);

  function setFacetValue(facetId: Facet, values: [number, number]) {
    setFacetValues({
      ...facetValues,
      [facetId]: values,
    });
  }

  return {
    facets,
    facetMinMaxes: minMaxes,
    facetValues,
    setFacetValue,
  };
};

export default useHouseFacets;
