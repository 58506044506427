import { useEffect, useState } from 'react';

function useWindowSize() {
  const [ windowSize, setWindowSize ] = useState<{ width: number; height: number; }>(getWindowSize());

  function getWindowSize(){
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;
