import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { useOutsideClickListener } from 'hooks';
import { HouseColumn } from 'store/houses/models';
import { CheckboxIcon, Sortable, VerticalOnlyScrollPane } from 'Components';

interface Props<T extends { label: string, value: HouseColumn; }> {
  sortedColumns: T[];
  shownColumns: HouseColumn[];
  toggleShownColumn: (column: HouseColumn) => void;
  setColumnOrders: (columns: T[]) => void;
}

const ColumnMetadataDropdown = <T extends { label: string, value: HouseColumn; }>({ sortedColumns, shownColumns, ...props }: Props<T>) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [ isDropdownOpen, setIsDropdownOpen ] = useState(false);

  useOutsideClickListener(dropdownRef, (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      if (isDropdownOpen) {
        closeDropdown();
      }
    }
  }, [ isDropdownOpen ]);

  function toggleDropdown() {
    setIsDropdownOpen(!isDropdownOpen);
  }

  function closeDropdown() {
    setIsDropdownOpen(false);
  }

  return (
    <div className="position-relative" ref={dropdownRef}>
      <ButtonDropdown isOpen={isDropdownOpen} toggle={_.noop}>
        <DropdownToggle color="light" caret onClick={toggleDropdown}>
          <i className="mdi mdi-lightning-bolt" />
        </DropdownToggle>
        <DropdownMenu right>
          <VerticalOnlyScrollPane style={{ maxHeight: 300 }}>
            <Sortable items={sortedColumns} idAccessor={(item) => item.value} onOrderChange={props.setColumnOrders}>
              {
                _.map(sortedColumns, (column) => {
                  const isSelected = _.includes(shownColumns, column.value);

                  return (
                    <DropdownItem key={column.value} onClick={() => props.toggleShownColumn(column.value)}>
                      <span className="child-spacing-x-1">
                        <i className="mdi mdi-drag"/>
                        <CheckboxIcon isSelected={isSelected}/>
                        <span>{column.label}</span>
                      </span>
                    </DropdownItem>
                  );
                })
              }
            </Sortable>
          </VerticalOnlyScrollPane>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

export default ColumnMetadataDropdown;
