import React from 'react';
import classnames from 'classnames';

export interface IconExtendableProps extends Omit<React.HTMLProps<HTMLSpanElement>, 'size' | 'ref'> {
  size?: 'sm' | 'md' | 'lg';
}

interface Props extends IconExtendableProps {
}

const Icon = ({ size, className, ...rest }: Props) => {
  return (
    <i {...rest} className={classnames('mdi ', className, { 'mdi-24px': size === 'md', 'mdi-36px': size === 'lg' })} />
  );
};

export default React.memo(Icon);
