import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { subMonths } from 'date-fns';
import { useDispatch } from 'react-redux';
import numeral from 'numeral';
import { Label } from 'reactstrap';

import { availableColumns, getPointsFromBoundingBox, MapBoundingBox } from 'core';
import { Point } from 'store/models';
import { housesActions, housesSelectors } from 'store/houses';
import { useStateSelector } from 'hooks';
import { HouseColumn } from 'store/houses/models';
import {
  ColumnMetadataDropdown,
  FlexRow,
  HouseMarker,
  HousesMap,
  MapFlexContainer,
  Panel,
  SplitLayout,
} from 'Components';
import HouseTable from './HouseTable';
import useWindowBreakpoints from '../../hooks/useWindowBreakpoints';
import MobileHousesPanel from './MobileHousesPanel';

const defaultDate = subMonths(new Date(), 100).toISOString();

const HousesPage = () => {
  const dispatch = useDispatch();
  const [ sortedColumns, setSortedColumns ] = useState(availableColumns);
  const [ bounds, setBounds ] = useState<Point[]>([]);
  const houses = useStateSelector(state => state.houses.ui.houses);
  const windowBreakpoints = useWindowBreakpoints();
  const filteredHouses = useStateSelector(housesSelectors.selectFilteredHouses);
  const shownColumnIds = useStateSelector(state => state.houses.ui.shownColumns);
  const shownColumns = useStateSelector(state => {
    return sortedColumns.filter(c => _.includes(state.houses.ui.shownColumns, c.value));
  });

  function handleBoundingBoxChange(boundingBox: MapBoundingBox) {
    const points = getPointsFromBoundingBox(boundingBox);
    setBounds(points);
  }

  function toggleShownColumn(columnId: HouseColumn) {
    dispatch(housesActions.toggleShownColumn(columnId));
  }

  useEffect(() => {
    if(_.isEmpty(bounds)) {
      return;
    }

    dispatch(housesActions.async.searchHouses({
      bounds,
      since: defaultDate,
    }));
  }, [ bounds ]);

  if(windowBreakpoints.xs || windowBreakpoints.sm) {
    return (
      <MobileHousesPanel
        handleBoundingBoxChange={handleBoundingBoxChange}
        shownColumns={shownColumns}
        filteredHouses={filteredHouses}
      />
    );
  }

  return (
    <SplitLayout panes={[
      {
        ratio: { xs: 1, sm: 1, md: 2, lg: 2, xl: 2 },
        Component: (
          <MapFlexContainer>
            <HousesMap
              onMapBoundingBoxLoaded={handleBoundingBoxChange}
              onMapBoundingBoxChange={handleBoundingBoxChange}
              zoom={12}
              center={{ lat: 43.026486454006594, lng: -87.9524535099127 }}>
              {/*<MarkerClusterGroup spiderfyOnMaxZoom={false} maxClusterRadius={40}>*/}
              {
                _.map(_.take(filteredHouses, 800), (house) => {
                  return (
                    <HouseMarker
                      key={house.id}
                      house={house}
                      position={[ house.latitude, house.longitude ]}
                    />
                  );
                })
              }
              {/*</MarkerClusterGroup>*/}
            </HousesMap>
          </MapFlexContainer>
        ),
      },
      {
        ratio: { xs: 0, sm: 0, md: 1, lg: 2, xl: 2 },
        Component: (
          <Panel className="px-2 py-2">
            <FlexRow alignCenter justifyBetween className="mb-1">
              <FlexRow alignCenter childSpacingX={1}>
                <div>
                  <Label>Houses ({filteredHouses.length} / {houses.length}) | {numeral(filteredHouses.length / houses.length).format('0.0%')}</Label>
                </div>
                <div>
                  <Label>Columns ({_.size(shownColumns)} / {sortedColumns.length})</Label>
                </div>
              </FlexRow>

              <div>
                <ColumnMetadataDropdown
                  sortedColumns={sortedColumns}
                  shownColumns={shownColumnIds}
                  toggleShownColumn={toggleShownColumn}
                  setColumnOrders={(columns) => setSortedColumns(columns)}
                />
              </div>
            </FlexRow>
            <Panel>
              <HouseTable columns={shownColumns} houses={filteredHouses} />
            </Panel>
          </Panel>
        ),
      },
    ]}/>
  );
};

export default HousesPage;
