import _ from 'lodash';
import { House } from '../store/houses/models';


export const getHouseListSalePriceDifference = (house: House) => {
  return house.soldPrice && house.listPrice ? house.soldPrice - house.listPrice : undefined;
};

export const getHouseDifferenceColor = (house: House) => {
  const difference = getHouseListSalePriceDifference(house);

  if(_.isNil(difference)) {
    return '#999999';
  }

  if(difference === 0) {
    return '#8fb8e8';
  }

  if(difference > 0) {
    if(difference < 5000) {
      return '#d2cf45';
    }

    if(difference < 10000) {
      return '#cc9c6b';
    }

    if(difference < 20000) {
      return '#d98892';
    }

    if(difference < 30000) {
      return '#d05463';
    }

    return '#ea0808';
  }

  if(difference < 0) {
    if(difference > -5000) {
      return '#c5d30b';
    }

    return '#3ead30';
  }
};
