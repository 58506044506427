import React from 'react';
import { Popup } from 'react-leaflet';
import { House } from 'store/houses/models';
import { ClickableDiv } from 'Components';
import numeral from 'numeral';
import { differenceInDays, format, parseISO } from 'date-fns';

interface Props {
  house: House;
}

const HouseMarkerPopup = ({ house, ...props }: Props) => {
  const daysBetweenListAndSale = !!house.listDate && !!house.soldDate ? differenceInDays(parseISO(house.soldDate), parseISO(house.listDate)) : undefined;
  const difference = house.soldPrice && house.listPrice ? house.soldPrice - house.listPrice : undefined;

  return (
    <Popup autoPan={false}>
      <ClickableDiv onClick={() => window.open(house.url, '_blank')}>
        <h5 className="child-spacing-x-1">{house.address}, {house.city}, {house.state} {house.zip}</h5>
        {!!house.listDate && house.listPrice && (
          <div>Listed on {format(parseISO(house.listDate), 'yyyy-MM-dd')} for {numeral(house.listPrice).format('$0,0.00')}</div>
        )}
        {!!house.soldDate && house.soldPrice && (
          <div>Sold on {format(parseISO(house.soldDate), 'yyyy-MM-dd')} for {numeral(house.soldPrice).format('$0,0.00')}</div>
        )}
        {!!daysBetweenListAndSale && (
          <div>{daysBetweenListAndSale} Days on the Market</div>
        )}
        {!!difference && (
          <div>{numeral(Math.abs(difference)).format('$0,0.00')} {difference > 0 && 'Over Asking'}{difference < 0 && 'Under Asking'}</div>
        )}
        {difference === 0 && (
          <div>At Asking</div>
        )}
      </ClickableDiv>
    </Popup>
  );
};

export default HouseMarkerPopup;
