import { LatLngBounds } from 'leaflet';
import { Point } from 'store/models';

export type MapBoundingBox = [[number, number],[number, number],[number, number],[number, number]];

export const getBoundingBoxFromMapBounds = (latLngBounds: LatLngBounds): MapBoundingBox => {
  return [
    [ latLngBounds.getNorthEast().lat, latLngBounds.getNorthEast().lng ],
    [ latLngBounds.getNorthWest().lat, latLngBounds.getNorthWest().lng ],
    [ latLngBounds.getSouthWest().lat, latLngBounds.getSouthWest().lng ],
    [ latLngBounds.getSouthEast().lat, latLngBounds.getSouthEast().lng ],
  ];
};

export const getPointsFromBoundingBox = (boundingBox: MapBoundingBox): Point[] => {
  const [ firstPoint ] = boundingBox;
  return boundingBox.map(([ lat, lng ]) => ({ latitude: lat, longitude: lng })).concat([ { latitude: firstPoint[0], longitude: firstPoint[1] } ]);
};
