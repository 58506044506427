import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { ApiLoadingStatusPayload } from 'core/api/models';
import { House, HouseColumn } from './models';
import { defaultMinMaxes, Facet } from '../../core';

const shownColumnsStoreKey = 'sr_house_shownItems';

export interface HousesState {
  ui: {
    houses: House[];
    shownColumns: HouseColumn[];
    showFilterPanel: boolean;
    minMaxes: Record<Facet, [number, number]>;
    facetValues: Record<Facet, [number, number]>;
  };
  loading: {
    isSearchingHouses: boolean;
  };
}

const defaultShownColumns = [
  HouseColumn.Address,
  HouseColumn.Beds,
  HouseColumn.Baths,
  HouseColumn.ListPrice,
  HouseColumn.SoldPrice,
  HouseColumn.Difference,
  HouseColumn.Url,
];

const storedShownColumnString = localStorage.getItem(shownColumnsStoreKey);
const storedShownColumns = storedShownColumnString ? JSON.parse(storedShownColumnString) : undefined;

const initialHousesState: HousesState = {
  ui: {
    shownColumns: storedShownColumns || defaultShownColumns,
    houses: [],
    showFilterPanel: false,
    minMaxes: defaultMinMaxes,
    facetValues: { ...defaultMinMaxes },
  },
  loading: {
    isSearchingHouses: false,
  },
};

export const housesSlice = createSlice({
  name: 'Houses',
  initialState: initialHousesState,
  reducers: {
    setLoading: (state, action: PayloadAction<ApiLoadingStatusPayload<HousesState>>) => {
      action.payload.handle(state, action.payload.isLoading);
    },
    toggleFilterPanel: (state) => {
      state.ui.showFilterPanel = !state.ui.showFilterPanel;
    },
    setFacetValues: (state, action: PayloadAction<Record<Facet, [number, number]>>) => {
      state.ui.facetValues = action.payload;
    },
    setMinMaxes: (state, action: PayloadAction<Record<Facet, [number, number]>>) => {
      state.ui.minMaxes = action.payload;
    },
    setSearchedHouses: (state, action: PayloadAction<House[]>) => {
      state.ui.houses = action.payload;
    },
    toggleShownColumn: (state, action: PayloadAction<HouseColumn>) => {
      const isActive = _.includes(state.ui.shownColumns, action.payload);
      state.ui.shownColumns = isActive ? _.without(state.ui.shownColumns, action.payload) : _.union(state.ui.shownColumns, [ action.payload ]);
      localStorage.setItem(shownColumnsStoreKey, JSON.stringify(state.ui.shownColumns));
    },
    setColumnOrders: (state, action: PayloadAction<HouseColumn[]>) => {
      state.ui.shownColumns = action.payload;
    },
  },
});
